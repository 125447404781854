import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom'; // Import useLocation

// Manually set the number of images in each folder
const TOTAL_QUESTIONS = 14;
const TOTAL_ANSWERS = 18;

const reviews = [
  { text: '"Deeply pleasurable."' },
  { text: '"Gives me belly laughter."' },
  { text: '"Unveils the dirtiest secrets."' },
  { text: '"Beyond fun!"' },
];

const phrases = [
  'what turns you on?',
  'tuttO è possibile',
  'anything is possible'
];

function App() {
  const [showInstructions, setShowInstructions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [showCards, setShowCards] = useState(false);
  const [questionCard, setQuestionCard] = useState(1);
  const [answerCards, setAnswerCards] = useState([1, 2, 3, 4]);
  const [eventImages, setEventImages] = useState([]);
  const [currentReview, setCurrentReview] = useState(0);
  const [currentPhrase, setCurrentPhrase] = useState('What turns you on?');
  const [isChanging, setIsChanging] = useState(false);

  const location = useLocation(); // Get the current location at the top level

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      setIsChanging(true);
      setTimeout(() => {
        currentIndex = (currentIndex + 1) % phrases.length;
        setCurrentPhrase(phrases[currentIndex]);
        setIsChanging(false);
      }, 1000); // 1 second for fade out/in effect
    }, 3000); // Change phrase every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleBuyNowClick = () => {
    const stripeLink = location.pathname === '/game' 
      ? 'https://buy.stripe.com/28ocN1aAo3Mz32U3cg' // Different link for /game
      // : 'https://buy.stripe.com/8wM14j0ZO1ErcDu8wx'; // Original link
      : 'https://buy.stripe.com/cN26oDgYMbf17jaeUZ'; // Original link


    window.location.href = stripeLink; // Redirect to the appropriate link
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      setCursorPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    const cardTimer = setTimeout(() => setShowCards(true), 500);

    // Load event images
    const eventImagesContext = require.context('../public/Events', false, /\.(png|jpe?g|svg)$/);
    const images = eventImagesContext.keys().map(eventImagesContext);
    setEventImages(images);

    const reviewInterval = setInterval(() => {
      setCurrentReview((prev) => (prev + 1) % reviews.length);
    }, 3000); // Change review every 5 seconds

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(cardTimer);
      clearInterval(reviewInterval);
    };
  }, []);

  useEffect(() => {
    const changeInterval = setInterval(() => {
      setIsChanging(true);
      setTimeout(() => {
        replaceCard('question');
        answerCards.forEach((_, index) => replaceCard('answer', index));
        setIsChanging(false);
      }, 1000); // Duration of the change animation
    }, 3000);

    return () => clearInterval(changeInterval);
  }, [answerCards]);

  const replaceCard = (type, index) => {
    if (type === 'question') {
      setQuestionCard(prevCard => (prevCard % TOTAL_QUESTIONS) + 1);
    } else {
      setAnswerCards(prevCards => {
        const newCards = [...prevCards];
        newCards[index] = (newCards[index] % TOTAL_ANSWERS) + 1;
        return newCards;
      });
    }
  };

  return (
    <Routes> {/* Use Routes instead of Switch */}
      <Route path="/game" element={(
        <div className="App">
          <div
            className="blush"
            style={{
              left: `${cursorPosition.x}px`,
              top: `${cursorPosition.y}px`,
            }}
          ></div>
          <div className="game-title">
            <h1>
              <span className="main-title">play tuttO</span>
              <span className="subtitle">the flirting game</span>
            </h1>
          </div>
          <div className="game-area">
            <div 
              className={`question-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
              onClick={() => replaceCard('question')}
            >
              <img src={`/Questions/q${questionCard}.jpg`} alt="Question Card" />
            </div>
            <div className="answer-cards">
              {answerCards.map((card, index) => (
                <div
                  key={index}
                  className={`answer-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
                  style={{ animationDelay: `${index * 0.1}s` }}
                  onClick={() => replaceCard('answer', index)}
                >
                  {index === 3 ? (
                    <div className="turn-on-card">
                      <img src="/turnon.jpg" alt="Turn On Card" />
                      <div className="turn-on-text">Write what turns you on</div>
                    </div>
                  ) : (
                    <img src={`/Answers/a${card}.jpg`} alt={`Answer Card ${index + 1}`} />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="game-explanation">
            <h2>tuttO is the hottest  <br />new party game  <br /><br />players take turns  <br /> coming up with  <br /> the flirtiest way <br /><br /> to make each<br /> other blush  </h2>
          </div>
          <div className="product-image">
            <img src="/box.png" alt="Tutto Game Box" />
          </div>

          <div className="game-contents">
            <p className="small-text">contents:</p>
            <p>69 blush cards</p>
            <p>210 turnOn cards</p>
            <p>210 flirtbOt cards</p>
          </div>

          <button className="buy-now-button" onClick={handleBuyNowClick}>
            order now
          </button>

          <div className="laurel-container">
            <div className="laurel left"></div>
            <div className="review-container">
              <p className="review-text">{reviews[currentReview].text}</p>
            </div>
            <div className="laurel right"></div>
          </div>

          <div className="event-slideshow">
            <div className="event-slideshow-inner">
              {eventImages.map((image, index) => (
                <img 
                  key={index}
                  src={image}
                  alt={`Event ${index + 1}`}
                  className="event-image"
                />
              ))}
              {eventImages.map((image, index) => (
                <img 
                  key={`duplicate-${index}`}
                  src={image}
                  alt={`Event ${index + 1}`}
                  className="event-image"
                />
              ))}
            </div>
          </div>

          <div className="game-description">
            <p>
              experience the pleasure of <br />playful flirting,
              light-hearted competition & the tuttO thrill<br />
              escalates till you reach your happy ending
            </p>
            <p className={`turn-on-question ${isChanging ? 'changing' : ''}`}>
              {currentPhrase}
            </p>
          </div>

          <button className="instructions-button" onClick={toggleInstructions}>
            Click here to read full game instructions
          </button>

          {showInstructions && (
            <div className="instructions-images">
              <img src="/front.jpg" alt="Game instructions front" className="instruction-image" />
              <img src="/back.jpg" alt="Game instructions back" className="instruction-image" />
            </div>
          )}

          <div className="footer">
            <a 
              href="https://www.instagram.com/playtutto" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="instagram-link"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <p className="copyright">© tutto llc 2024</p>
          </div>
        </div>
      )} />
      <Route path="/" element={(
        <div className="App">
          <div
            className="blush"
            style={{
              left: `${cursorPosition.x}px`,
              top: `${cursorPosition.y}px`,
            }}
          ></div>
          <div className="game-title">
            <h1>
              <span className="main-title">play tuttO</span>
              <span className="subtitle">the flirting game</span>
            </h1>
          </div>
          <div className="game-area">
            <div 
              className={`question-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
              onClick={() => replaceCard('question')}
            >
              <img src={`/Questions/q${questionCard}.jpg`} alt="Question Card" />
            </div>
            <div className="answer-cards">
              {answerCards.map((card, index) => (
                <div
                  key={index}
                  className={`answer-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
                  style={{ animationDelay: `${index * 0.1}s` }}
                  onClick={() => replaceCard('answer', index)}
                >
                  {index === 3 ? (
                    <div className="turn-on-card">
                      <img src="/turnon.jpg" alt="Turn On Card" />
                      <div className="turn-on-text">Write what turns you on</div>
                    </div>
                  ) : (
                    <img src={`/Answers/a${card}.jpg`} alt={`Answer Card ${index + 1}`} />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="game-explanation">
            <h2>tuttO is the hottest  <br />new party game  <br /><br />players take turns  <br /> coming up with  <br /> the flirtiest way <br /><br /> to make each<br /> other blush  </h2>
          </div>
          <div className="product-image">
            <img src="/box.png" alt="Tutto Game Box" />
          </div>

          <div className="game-contents">
            <p className="small-text">contents:</p>
            <p>69 blush cards</p>
            <p>210 turnOn cards</p>
            <p>210 flirtbOt cards</p>
          </div>

          <button className="buy-now-button" onClick={handleBuyNowClick}>
            order now
          </button>

          <div className="laurel-container">
            <div className="laurel left"></div>
            <div className="review-container">
              <p className="review-text">{reviews[currentReview].text}</p>
            </div>
            <div className="laurel right"></div>
          </div>

          <div className="event-slideshow">
            <div className="event-slideshow-inner">
              {eventImages.map((image, index) => (
                <img 
                  key={index}
                  src={image}
                  alt={`Event ${index + 1}`}
                  className="event-image"
                />
              ))}
              {eventImages.map((image, index) => (
                <img 
                  key={`duplicate-${index}`}
                  src={image}
                  alt={`Event ${index + 1}`}
                  className="event-image"
                />
              ))}
            </div>
          </div>

          <div className="game-description">
            <p>
              experience the pleasure of <br />playful flirting,
              light-hearted competition & the tuttO thrill<br />
              escalates till you reach your happy ending
            </p>
            <p className={`turn-on-question ${isChanging ? 'changing' : ''}`}>
              {currentPhrase}
            </p>
          </div>

          <button className="instructions-button" onClick={toggleInstructions}>
            Click here to read full game instructions
          </button>

          {showInstructions && (
            <div className="instructions-images">
              <img src="/front.jpg" alt="Game instructions front" className="instruction-image" />
              <img src="/back.jpg" alt="Game instructions back" className="instruction-image" />
            </div>
          )}

          <div className="footer">
            <a 
              href="https://www.instagram.com/playtutto" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="instagram-link"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <p className="copyright">© tutto llc 2024</p>
          </div>
        </div>
      )} />
    </Routes>
  );
}

export default App;

 // import React, { useState, useEffect } from 'react';
// import './App.css';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // Add this import

// // Manually set the number of images in each folder
// const TOTAL_QUESTIONS = 14;
// const TOTAL_ANSWERS = 18;


// const reviews = [
//   { text: '"Deeply pleasurable."' },
//   { text: '"Gives me belly laughter."' },
//   { text: '"Unveils the dirtiest secrets."' },
//   { text: '"Beyond fun!"' },
// ];


// const phrases = [
//   'what turns you on?',
//   'tuttO è possibile',
//   'anything is possible'
// ];

// function App() {
//   const [showInstructions, setShowInstructions] = useState(false);

//   const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
//   const [showCards, setShowCards] = useState(false);
//   const [questionCard, setQuestionCard] = useState(1);
//   const [answerCards, setAnswerCards] = useState([1, 2, 3, 4]);
//   const [eventImages, setEventImages] = useState([]);
//   const [currentReview, setCurrentReview] = useState(0);
//    const [currentPhrase, setCurrentPhrase] = useState('What turns you on?');
//   const [isChanging, setIsChanging] = useState(false);
//   const toggleInstructions = () => {
//     setShowInstructions(!showInstructions);
//   };

//   useEffect(() => {
//     let currentIndex = 0;
//     const intervalId = setInterval(() => {
//       setIsChanging(true);
//       setTimeout(() => {
//         currentIndex = (currentIndex + 1) % phrases.length;
//         setCurrentPhrase(phrases[currentIndex]);
//         setIsChanging(false);
//       }, 1000); // 1 second for fade out/in effect
//     }, 3000); // Change phrase every 3 seconds

//     return () => clearInterval(intervalId);
//   }, []);

//   const handleBuyNowClick = () => {
//     window.location.href = 'https://buy.stripe.com/8wM14j0ZO1ErcDu8wx';
//   };

//   useEffect(() => {
//     const handleMouseMove = (event) => {
//       setCursorPosition({ x: event.clientX, y: event.clientY });
//     };

//     window.addEventListener('mousemove', handleMouseMove);

//     const cardTimer = setTimeout(() => setShowCards(true), 500);

//     // Load event images
//     const eventImagesContext = require.context('../public/Events', false, /\.(png|jpe?g|svg)$/);
//     const images = eventImagesContext.keys().map(eventImagesContext);
//     setEventImages(images);

//     const reviewInterval = setInterval(() => {
//       setCurrentReview((prev) => (prev + 1) % reviews.length);
//     }, 3000); // Change review every 5 seconds
 

//     return () => {
//       window.removeEventListener('mousemove', handleMouseMove);
//       clearTimeout(cardTimer);
//       clearInterval(reviewInterval);

//     };
//   }, []);



//   useEffect(() => {
//     const changeInterval = setInterval(() => {
//       setIsChanging(true);
//       setTimeout(() => {
//         replaceCard('question');
//         answerCards.forEach((_, index) => replaceCard('answer', index));
//         setIsChanging(false);
//       }, 1000); // Duration of the change animation
//     }, 3000);

//     return () => clearInterval(changeInterval);
//   }, [answerCards]);


//   const replaceCard = (type, index) => {
//     if (type === 'question') {
//       setQuestionCard(prevCard => (prevCard % TOTAL_QUESTIONS) + 1);
//     } else {
//       setAnswerCards(prevCards => {
//         const newCards = [...prevCards];
//         newCards[index] = (newCards[index] % TOTAL_ANSWERS) + 1;
//         return newCards;
//       });
//     }
//   };

//   return (
//     <div className="App">
//       <div
//         className="blush"
//         style={{
//           left: `${cursorPosition.x}px`,
//           top: `${cursorPosition.y}px`,
//         }}
//       ></div>
//          <div className="game-title">
//         <h1>
//           <span className="main-title">play tuttO</span>
//           <span className="subtitle">the flirting game</span>
//         </h1>
//       </div>
//       <div className="game-area">
//         <div 
//           className={`question-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
//           onClick={() => replaceCard('question')}
//         >
//           <img src={`/Questions/q${questionCard}.jpg`} alt="Question Card" />
//         </div>
//         <div className="answer-cards">
//           {answerCards.map((card, index) => (
//             <div
//               key={index}
//               className={`answer-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
//               style={{ animationDelay: `${index * 0.1}s` }}
//               onClick={() => replaceCard('answer', index)}
//             >
//               {index === 3 ? (
//                 <div className="turn-on-card">
//                   <img src="/turnon.jpg" alt="Turn On Card" />
//                   <div className="turn-on-text">Write what turns you on</div>
//                 </div>
//               ) : (
//                 <img src={`/Answers/a${card}.jpg`} alt={`Answer Card ${index + 1}`} />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className="game-explanation">
//         <h2>tuttO is the hottest  <br />new party game  <br /><br />players take turns  <br /> coming up with  <br /> the flirtiest way <br /><br /> to make each<br /> other blush  </h2>
//       </div>
//       <div className="product-image">
//     <img src="/box.png" alt="Tutto Game Box" />
//   </div>

//   <div className="game-contents">
//   <p className="small-text">contents:</p>

//     <p>69 blush cards</p>
//     <p>210 turnOn cards</p>
//     <p>210 flirtbOt cards</p>
//   </div>

//   <button className="buy-now-button" onClick={handleBuyNowClick}>
//         order now
//       </button>

//       <div className="laurel-container">
//         <div className="laurel left"></div>
//         <div className="review-container">
//           <p className="review-text">{reviews[currentReview].text}</p>
//         </div>
//         <div className="laurel right"></div>
//       </div>

//       {/* <button className="buy-now-button">order now</button> */}

//       <div className="event-slideshow">
//   <div className="event-slideshow-inner">
//     {eventImages.map((image, index) => (
//       <img 
//         key={index}
//         src={image}
//         alt={`Event ${index + 1}`}
//         className="event-image"
//       />
//     ))}
//     {eventImages.map((image, index) => (
//       <img 
//         key={`duplicate-${index}`}
//         src={image}
//         alt={`Event ${index + 1}`}
//         className="event-image"
//       />
//     ))}
//   </div>
// </div>

// <div className="game-description">
//         <p>
//           experience the pleasure of <br />playful flirting,
//           light-hearted competition & the tuttO thrill<br />
//           escalates till you reach your happy ending
//         </p>
//         <p className={`turn-on-question ${isChanging ? 'changing' : ''}`}>
//           {currentPhrase}
//         </p>
//       </div>



//       <button className="instructions-button" onClick={toggleInstructions}>
//   Click here to read full game instructions
// </button>

// {showInstructions && (
//   <div className="instructions-images">
//     <img src="/front.jpg" alt="Game instructions front" className="instruction-image" />
//     <img src="/back.jpg" alt="Game instructions back" className="instruction-image" />
//   </div>
// )}

// <div className="footer">
//   <a 
//     href="https://www.instagram.com/playtutto" 
//     target="_blank" 
//     rel="noopener noreferrer" 
//     className="instagram-link"
//   >
//     <i className="fab fa-instagram"></i>
//   </a>
//   <p className="copyright">© tutto llc 2024</p>
// </div>
//     </div>
    
//   );
// }

// export default App;